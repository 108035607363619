.is-changelogs-page__header {
  .hero-body {
    @include desktop {
      padding-top: 3rem !important;
    }
  }
}

.is-changelogs-page__content {
  min-height: 50vh;
  color: $white;

  .changelogs-item {
    position: relative;

    .changelogs-item__block {
      padding-bottom: 1.5rem;

      ul {
        list-style: none;
        margin: 0;

        li {
          padding-left: 1.25rem;
          position: relative;

          ul li::before {
            content: "•";
          }
        }
        li::before {
          content: "■";
          position: absolute;
          left: 0;
          font-size: 1em;
          color: $primary;
        }
      }

      .block-img {
        text-align: center;
      }
    }

    .cta-helper {
      border-radius: $radius;
      padding: 1.5rem;
      text-align: center;
      background-color: $sc-c-3;
    }
  }

  .changelogs-item + .changelogs-item {
    &::before {
      background-image: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 0,
        $sc-white 5%,
        $sc-white 90%,
        rgba(0, 0, 0, 0) 100%
      );
      content: "";
      display: block;
      height: 2px;
      opacity: 0.08;
      position: relative;
      margin: auto;
      max-width: 960px;
      top: 0;
      left: 0;
      right: 0;
      margin: 3rem 0;
    }
  }

  .easy-read {
    strong {
      color: inherit;
    }
    a {
      color: inherit;
      font-weight: 600;
    }
  }
}

.date {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid rgba($sc-white, 0.7);
  border-radius: $radius;
  font-family: monospace;
  font-size: $size-8;
  margin-bottom: 1.5rem;
}
