.is-story-page__header {
  position: relative;
  
  .hero-body {
    position: relative;
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;
    
    @include desktop {
      padding-top: 3rem !important;
    }
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .company__logo {
    max-height: 4rem;
    filter: invert(1);
  }

  .quote {
    font-family: $family-serif;
    padding: 3rem;

    .quote__text {
      margin-bottom: 1.5rem;
    }

    .image {
      display: inline-block;
    }
  }
}

.is-story-page__content {
  background-color: $white;
  min-height: 50vh;
  padding-top: 0;
  padding-bottom: 3rem;

  --icon-color: #{rgba($sc-c-9, .75)};

  a.icon:hover {
    --icon-color: #{rgba($sc-c-9, 1)};
  }

  .icon {
    margin: 4px;
  }

  .section {
    padding: 1.5rem;

    &.is-separator {
      position: relative;
      text-align: center;
      padding: inherit;

      &::before {
        background-image: linear-gradient(-90deg,rgba(255,255,255,0) 0, $sc-c-2 5%, $sc-c-2 90%,rgba(255,255,255,0) 100%);
        content: "";
        display: block;
        height: 2px;
        opacity: .08;
        position: absolute;
        margin: auto;
        max-width: 720px;
        top: 50%;
        left: 0;
        right: 0;
      }
    }
  }

  .container {
    max-width: 720px;
  }

  .company__detail {
    background-color: rgba($sc-c-9, .3);
    padding: 3rem 0 0 0;

    @include tablet {
      padding: 3rem 1.5rem;
    }

    .container {
      @include tablet {
        display: flex;
      }
      p, ul, li {
        font-family: $family-sans-serif;
      }
    }

    .company__detail__about {
      display: flex;
      flex-wrap: wrap;

      @include mobile {
        padding: 0 1.5rem 3rem 1.5rem;
      }

      .company__detail__about__description, .company__detail__about__block {
        padding-right: 2rem;
      }
      .company__detail__about__block {
        padding-top: 1rem;
      }
    }

    .company__detail__infos {
      background-color: rgba($sc-c-9, .3);
      min-width: 240px;
      padding: 1.5rem;
      margin-right: auto;
      flex-shrink: 0;
      align-self: flex-start;

      span {
        display: block;

        &:nth-of-type(2), &:nth-of-type(3) {
          margin-top: 1rem;
        }
      }

      ul {
        margin: .5rem 0 0 1.25rem;
        list-style-type: disc;
      }
    }

  }

  .is-story-page__content__header {
    .title {
      padding: 1.5rem 0;
      @include tablet {
        padding-top: 3rem;
      }
    }
    p {
      font-family: $family-sans-serif !important;
      color: rgba($sc-c-1, .9);
    }
  }

  .content {
    color: rgba($sc-c-1, .8);
    line-height: 1.8;
    font-size: 1.175em;

    h1 {
      color: $sc-c-3;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $sc-c-4;
    }

    p, ul, li {
      font-family: $family-serif;
      color: rgba($sc-c-1, .8);
    }

    [data-oembed-provider="YouTube"] {
      display: block;
      margin: 1.5rem auto;

      div {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .is-highlight {
      padding: .25rem .35rem;
      margin: 0 .15rem;
      background-color: rgba($sc-c-9, .25);
      color: rgba($sc-c-1, 1);
    }

    .block-img {
      &.is-left-align {
        max-width: 40%;
        margin: 0 1.5rem 0 0;
        float: left;
      }
      &.is-right-align {
        max-width: 40%;
        margin: 0 0 0 1.5rem;
        float: right;
      }
    }

    // [data-oembed-provider="YouTube"] iframe {
    //   display: block;
    //   margin: 1.5rem auto;

    //   @include mobile {
    //     min-height: 11.5rem;
    //     height: auto;
    //     width: 100%;
    //   }
    // }
  } 


  .socials {
    border: 1px solid rgba($grey-light, .5);
    border-radius: 4px;
    padding: 1rem;

    h2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: .75rem;
    }
    .icon {
      vertical-align: middle;
    }
  }

  .is-fly {
    position: sticky;
    bottom: 60vh;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;

    .container {
      max-width: unset;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 125ms;

      &.is-visible {
        opacity: 1;
      }

      a {
        pointer-events: auto;
      }
    }
  }

  .related-articles {
    background-color: rgba($sc-c-9, .1);
    margin-top: 3rem;
    margin-bottom: -3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .blog-post {

      figure {
        margin-bottom: 1.5rem;
        transition: transform 250ms; 

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
          opacity: 0;
          transition: opacity 250ms;
        }
  
        img {
          object-fit: cover;
        }
      }

      .blog-post__header:hover {
        figure {
          transform: scale(1.0125);
          &:before {
            opacity: 1;
          }
        }
      }
  
      .blog-post__date {
        opacity: .3;
      }
    }
  }
}
