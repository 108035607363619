.is-feature-detailed-items {
  .columns {
    flex-direction: row-reverse;

    &:nth-child(odd) {
      flex-direction: row;
    }

    // Add transition for smooth appearance
    .column {
      transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
    }

    // Lottie animation column
    .column:first-child {
      opacity: 0;
      transform: translateY(20px);

      &.is-visible {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .columns:not(:last-child) {
    margin-bottom: 3rem;
    @include desktop {
      margin-bottom: 6rem;
    }
  }

  h2.title:not(:last-child) {
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    @include desktop {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }
  }

  .lottie {
    max-width: 80%;
    min-height: 207px;
    svg {
      max-height: 200px;
    }
  }

  ul {
    li {
      padding-left: 1.25rem;
      position: relative;
    }
    li::before {
      content: "•";
      position: absolute;
      left: 0;
      font-size: 1em;
      color: $easy-read;
    }
  }
}
