.is-reference-gallery {
  .title {
    margin: 2rem 0 4rem 0;
  }

  .logo-carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 1rem 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 150px;
      height: 100%;
      z-index: 2;
      pointer-events: none;

      @media (max-width: 768px) {
        width: 36px;
      }
    }

    &::before {
      left: 0;
      background: linear-gradient(
        to right,
        rgb(8 13 26) 0%,
        rgb(7 13 25 / 64%) 40%,
        transparent 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to left,
        rgb(8 13 26) 0%,
        rgb(7 13 25 / 64%) 40%,
        transparent 100%
      );
    }
  }

  .logo-track {
    display: flex;
    animation: scroll 30s linear infinite;
    width: fit-content;

    &:hover {
      animation-play-state: paused;
    }
  }

  .logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;

    img {
      max-height: 2.5rem;
      max-width: 6rem;
      width: auto;
      filter: invert(1);
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
