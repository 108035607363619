.footer {
  padding-bottom: 3rem;
  margin-top: auto;

  .copyright {
    opacity: 0.5;
    margin-top: 3rem;
  }

  .icon {
    margin: 0 4px;
  }
}
