.is-datasource {
  position: relative;
  .block {
    position: relative;
    border-radius: 24px;
    padding: 1.5rem;
    flex-grow: 1;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      padding: 1px;
      background: linear-gradient(90deg, #4b6eff, $sc-c-brand);
      border-radius: 24px;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      z-index: 0;
    }

    @include desktop {
      padding: 3rem 1.5rem;
    }
  }

  .source {
    padding: 1.5rem;
  }

  .image {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
