.is-sign-up-zone {
  padding: 0 1.5rem;

  .container {
    background-repeat: no-repeat;
    background-size: 100% 90%;
    background-position: center;
    padding: 6rem 0;

    .field.has-addons {
      input {
        background-color: $sc-c-1;
        border: 1px solid $sc-c-6;
      }
    }

    @include desktop {
      background-size: 100%;
      background-position: center 1.25rem;
      padding: 8rem 0;

      .field.has-addons {
        min-width: 26rem;
      }
    }
  }

  &.is-c-1 {
    .container {
      .field.has-addons {
        input {
          background-color: $sc-c-2;
        }
      }
    }
  }

  &.is-c-3:not(.is-cropped, .is-discret) {
    background-color: transparent;
  }

  &.is-cropped {
    background-color: transparent !important;
    .container {
      background-color: $sc-c-3;
      padding: 3rem 0;
    }
  }

  &.is-discret {
    background-color: transparent !important;
    .container {
      background-color: $sc-c-3;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      margin: 0 1rem;
      font-size: $size-6 !important;

      @include tablet {
        white-space: nowrap;
        font-size: $size-5 !important;
      }
    }
    .subtitle {
      display: none;
    }
    .button {
      margin: 0 1rem;
    }
  }
}

.nocreditcard {
  font-size: $size-8;
  color: $sc-white;
}
