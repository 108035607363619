.wio-link {
  position: fixed;
  bottom: 30px;
  right: 30px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background-color: #121212;
  color: white;
  //font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  line-height: 1;
  text-rendering: auto;

  &::before {
    //content: "\f303";
    content: "✎";
  }

  img {
    display: none;
  }
}
