.is-faq {
  .qa {
    position: relative;
    border-radius: $radius;
    border: 1px solid transparent;
    transition: border-color 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
      border-color: $sc-c-5;
    }
    &.is-expanded {
      border-color: $sc-c-3;
    }

    .question {
      position: relative;
      display: flex;
      padding: 0.75rem 1rem;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;

      @include mobile {
        padding: 0.75rem 0.5rem;
        gap: 0.5rem;
      }

      .tooltip {
        display: inline-block;
        white-space: nowrap;
      }

      .chevron-icon {
        flex-shrink: 0;
        transition: transform 0.3s ease;
        margin-right: 0.75rem;
        opacity: 0.6;
        transform: rotate(-90deg);
      }

      &:hover .chevron-icon {
        opacity: 1;
      }

      .chevron-icon.is-expanded {
        transform: rotate(0deg);
      }
    }

    .answer {
      display: none;
      padding: 1rem 3rem 2rem 3rem;
    }

    &.is-expanded {
      background-color: $sc-c-1;

      .answer {
        display: block;
      }
    }
  }
}
