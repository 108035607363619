.is-image-block {

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        figcaption {
            padding: 0.75rem;
            opacity: .6;
            text-align: center;
        }
    }

    &.is-small {
        figure {
            width: 50%;
        }
    }
    &.is-medium {
        figure {
            width: 75%;
        }
    }
    &.is-large {
        figure {
            width: 100%;
        }
    }
    &.is-emphasized {
        @include tablet {
            figure {
                width: 130%;
                max-width: 100vw;
            }
        }
    }
}