.hero.is-hero-parallax {
  position: relative;
  overflow: hidden;
  min-height: 300px;

  @include desktop {
    min-height: 720px;
  }
  
  .is-parallax__layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    will-change: transform;

    &.is-parallax__layer--front {
      transform: translateY(80%);
    }

    &.is-parallax__layer--back {
      
    }

    .title {
      margin: 1.5rem auto;
      position: relative;
      //text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
      text-align: center;
    }

    img {
      position: absolute;
      top: 0;
      margin: auto;
      left: 5%;
      right: 5%;
      width: calc(100% - 10%);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    }

    // @include tablet {
    //   .title {
    //     margin-left: 50%;
    //     margin-right: 1.5rem;
    //   }

    //   img {
    //     opacity: 1;
    //     width: 45%;
    //     left: 2.5%;
    //     right: auto;
    //   }
    // }

    @include desktop {
      .title {
        font-size: 3.375rem;
      }
    }
  }

}

// .hero.is-hero-parallax {
//   position: relative;
//   overflow: hidden;
//   /* please check _base.scss */
//   @include desktop {

//     .title {
//       margin-left: 50%;
//       position: relative;
//       // z-index: 2;
//       // transform: translateZ(-0.5px) scale(1.5) translateY(50%) translateX(20%);
//       // transform-origin: 50% 50%;
//       text-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
//     }

//     img {
//       max-width: calc(100% - 200px);
//       position: absolute;
//       margin: auto;
//       left: 100px;
//       right: 100px;
//       // transform: translateZ(0.5px) scale(0.5) translateY(-100%);
//       // transform-origin: 50% 100%;
//     }
//   }
// }

// @include desktop {
//   /* parallax */
//    @supports ((perspective: 1px) and (not (-webkit-overflow-scrolling: touch))) {
//      html, body {
//        overflow: hidden;
//      }
 
//      body {
//        transform: translateZ(0); /* Fix paint issues in Edge && Safari H/W acceleration */
//      }
 
//      #root {
//        height: 100vh;
//        perspective: 1px;
//        overflow-y: scroll;
//        overflow-x: hidden;
//        transform: translateZ(0);
//      }
 
//      section:not(.is-parallax__group) {
//        transform: translateZ(0);
//      }
//      section.is-parallax__group {
//        position: relative;
//        transform-style: flat;
 
//        .is-parallax__layer {
//          position: relative;
 
//          &.is-parallax__layer--base {
//            transform: translateZ(0);
//          }
//          &.is-parallax__layer--back {
//            transform: translateZ(-0.5px) scale(1.5);
//          }
//          &.is-parallax__layer--front {
//            transform: translateZ(.5px) scale(.5);
//          }
//          &.is-parallax__layer--front {
//            transform: translateZ(.5px) scale(.5);
//          }
//        }
//      }
 
//      section {
//        position: relative;
//      }
//    }
//  }