.is-homepage__header {
  @include desktop {
    min-height: calc(96vh - 286px); // 84 navbar, 202 reference-gallery one row
  }

  .hero-body {
    padding-top: 0;
    padding-bottom: 0;

    @include mobile {
      padding: 3rem 1.5rem 0rem 1.5rem;
    }

    .columns {
      @include mobile {
        flex-direction: column-reverse;
      }
    }

    .field.has-addons {
      max-width: 26rem;
    }

    .lottie {
      background: url("/img/home-animation-map.svg") no-repeat center;
      background-size: contain;
      svg,
      img {
        max-height: 400px;
      }
    }
  }
}
