.is-quote {

    .quote {
        border-left: 1px solid $primary;
        padding-left: 1.5rem;

        @include tablet {
            margin-left: 3rem;
            margin-right: 3rem;
        }

        .quote__text {
            line-height: 1.5;
        }
    }
    
    .media {
        align-items: center;
    }
    figure {
        margin: 0;
    }
    .quote__author-name {
        margin-bottom: -.25rem !important;
    }
}