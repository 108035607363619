.hero.is-hero-embed {
  position: relative;
  overflow: hidden;
  min-height: 300px;

  @include desktop {
    min-height: 720px;
  }

  .title {
    margin: 1.5rem auto;
    position: relative;
    //text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    text-align: center;

    @include desktop {
      font-size: 3.375rem;
    }
  }

  [data-oembed-provider="YouTube"] {
    display: block;
    margin: 3rem auto;
    max-width: 960px;

    @include desktop {
      margin: 6rem auto;
    }

    div {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
