// @include touch {
//   html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
//     padding-top: 3.25rem;
//   }
// }

html.has-spaced-navbar-fixed-top {
  padding-top: 0;

  .navbar.is-fixed-top {
    position: sticky;
    top: 0;
  }
}

.is-banner {
  padding: 0 0.75rem;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include touch {
      padding-right: 0.1rem;
    }

    > p {
      flex-grow: 1;
      text-align: center;
      padding: 0.75rem;

      a {
        text-decoration: underline;
      }
    }
  }
}

.is-header {
  @include touch {
    padding: 0 0.75rem;
  }

  .container {
    display: flex;
  }

  .is-logo {
    &:hover,
    &.is-active {
      background-color: transparent !important;
    }
    img {
      max-height: unset;
    }
    span {
      margin-left: 0.5rem;
      margin-top: 0.66rem;
    }

    @include desktop {
      img {
        margin-top: -0.5rem;
      }
      span {
        margin-top: 0;
      }
    }
  }

  .navbar-start {
    margin-right: 0;

    @include desktop {
      margin-left: auto;
    }
  }

  .navbar-end {
    .button {
      align-self: center;
      margin: 0.5rem 0.75rem;
    }
  }

  .navbar-start,
  .navbar-end {
    .navbar-item:not(.button) {
      position: relative;
      background-color: transparent !important;
      span {
        position: relative;
      }

      &:not(.is-active) {
        opacity: 0.6;
      }

      &.is-active span::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        left: 20%;
        right: 20%;
        height: 1px;
        background-color: currentColor;
      }

      @include desktop {
        &:hover span::before {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 20%;
          right: 20%;
          height: 1px;
          background-color: currentColor;
        }
      }
    }
  }

  .navbar-burger {
    background-color: transparent;
    border: none;
  }
  .navbar-burger span {
    background-color: currentColor;
    height: 2px;
  }

  @include touch {
    .navbar-burger.is-active {
      position: fixed;
      right: 0.75rem;
      top: 0;
    }

    .navbar-menu {
      transform-origin: center center;
      transition-duration: 0.5s;
      background-color: transparent;
      bottom: 0;
      box-shadow: none;
      left: 100%;
      padding: 6rem 1.5rem;
      position: fixed;
      top: 0;
      max-height: unset !important;
      transform: translateX(0);
      transition-property: transform;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      background-color: $sc-c-0;

      &.is-active {
        transform: translateX(-100%);
      }
    }

    &.is-c-3 {
      .navbar-menu {
        background-color: transparent;
      }
    }

    .navbar-end {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.hero {
  .lottie {
    opacity: 0;
    filter: blur(5px);
    animation: unblur 0.2s ease-out forwards;
    animation-delay: 0.3s;
  }

  .title {
    opacity: 0;
    animation: unblur 0.4s ease-out forwards;
    animation-delay: 0.4s;
  }

  .subtitle {
    opacity: 0;
    animation: unblur 0.7s ease-out forwards;
    animation-delay: 0.7s;

    strong {
      color: $sc-white;
    }
  }

  .field {
    opacity: 0;
    animation: unblur 0.7s ease-out forwards;
    animation-delay: 0.7s;
  }

  .nocreditcard {
    opacity: 0;
    animation: unblur 0.7s ease-out forwards;
    animation-delay: 0.7s;
  }

  .hero-cta {
    opacity: 0;
    transform: translateY(20px);
    animation: unblur 0.7s ease-out forwards;
    animation-delay: 1.7s;
  }
}

@keyframes unblur {
  from {
    filter: blur(10px);
    opacity: 0;
  }
  to {
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
