.is-block-detailed-items {

  .columns:not(:last-child) {
    margin-bottom: 3rem;
    @include desktop {
      margin-bottom: 6rem;
    }
  }

  .columns:nth-child(odd) {
    @include tablet {
      .column {
        &:nth-child(1) {
          padding-right: 1.5rem;
        }
        &:nth-child(2) {
          padding-left: 1.5rem;
        }
      }
    }
  }

  .columns:nth-child(even) {
    flex-direction: row-reverse;

    @include tablet {
      .column {
        &:nth-child(1) {
          padding-left: 1.5rem;
        }
        &:nth-child(2) {
          padding-right: 1.5rem;
        }
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 1.25rem;
      position: relative;
    }
    li::before {
      content: "■";
      position: absolute;
      left: 0;
      font-size: 1em;
      color: $primary;
    }
  }

  [data-oembed-provider] {
    div {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}