.is-chart-page__header {
  .hero-body {
    padding: 0 0 1.5rem 0;

    @include tablet {
      padding: 1.5rem;
    }
  }
  
  .container {
    border-radius: 4px;
    background-color: $sc-c-1;
    padding: 1.5rem;

    @include tablet {
      padding: 2.5rem 3rem;
    }
  }

  [data-tooltip] {
    vertical-align: middle;
  }
}

.is-chart-page__content {
  
  --icon-color: #{rgba($sc-c-9, .75)};

  a.icon:hover {
    --icon-color: #{rgba($sc-c-9, 1)};
  }

  .content {
    color: $white;
    strong {
      color: inherit;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  .icon {
    margin: 4px;
  }

  .socials {
    border: 1px solid rgba($grey-light, .5);
    border-radius: 4px;
    padding: 1rem;

    h2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: .75rem;
    }
    .icon {
      vertical-align: middle;
    }
  }

  .is-fly {
    position: sticky;
    bottom: 60vh;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;

    .container {
      max-width: unset;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 125ms;

      &.is-visible {
        opacity: 1;
      }

      a {
        pointer-events: auto;
      }
    }
  }

  .is-chart {
    position: relative;
    padding-top: 0;

    .chart-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $sc-c-2;
      flex-direction: column;
      padding-bottom: 1.5rem;

      .select {
        margin: .5rem;
        width: 100%;
        select {
          width: 100%;
        }
      }

      @include tablet {
        padding: 0 4.5rem;
        flex-direction: row;

        .select {
          width: 33%;
          margin: 1rem;
        }
      }
      @include desktop {
        position: sticky;
        top: calc(5.25rem - 1px);
        z-index: 1;
      }

    }

    .chart {
      @include tablet {
        padding: 0 4.5rem 3rem 4.5rem;
      }

      .loader-dual-ring {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 6rem;
      }

      .rank {
          display: flex;
          align-items: center;
          padding: 0 1rem;
          margin: 0 -1.5rem;

          &:nth-child(odd) {
            background-color: $sc-c-3;
          }

          @include tablet {
            padding: 0 1.5rem;
            margin: 0;
          }

          &.emphasized {
            background-color: $sc-c-4;
            margin: 0 -1.5rem;
            padding: .5rem 1rem;

            @include tablet {
              padding: .5rem 3rem;
            }
    
            .rank__name {
              font-size: $size-5 !important;
            }
            .rank__spins {
              font-size: $size-7;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
    
            @include tablet {
              .rank__name {
                font-size: $size-4 !important;
              }
              .rank__spins {
                font-size: $size-5;
              }
            }
          }

          .rank__position {
            margin: .5rem;
            text-align: center;
            flex-shrink: 0;
            width: 1.5rem;
            @include tablet {
              width: 3rem;
            }
          }

          .rank__detail {
            margin: .5rem 0;
          }

          .rank__visual {
            margin: .75rem 1.5rem;
            flex-shrink: 0;
            position: relative;

            @include mobile {
              height: 48px;
              width: 48px;
            }

            &::after {
              content: "";
              display: block;
              height: 2rem;
              width: 2rem;
              position: absolute;
              top: .5rem;
              left: -1rem;
              background-repeat: no-repeat;
              background-size: contain;

              @include mobile {
                left: -.75rem;
                height: 1.5rem;
                width: 1.5rem;
              }
            }

            &.evolution-new::after {
              background-image: url('/img/charts-evolution-new.svg');
            }
            &.evolution-up::after {
              background-image: url('/img/charts-evolution-up.svg');
            }
            &.evolution-down::after {
              background-image: url('/img/charts-evolution-down.svg');
            }
            &.evolution-re::after {
              background-image: url('/img/charts-evolution-re.svg');
            }
            &.evolution-equal::after {
              background-image: url('/img/charts-evolution-equal.svg');
            }
          }

          .rank__name, .rank__artists {
            display: block;
          }

          .rank__artists {
            a {
              &:hover {
                filter: brightness(116%);
              }
            }
          }

          .rank__spins {
            margin: .5rem .5rem .5rem auto;
            flex-shrink: 0;

            @include tablet {
              margin: 1.5rem .5rem 1.5rem auto;
            }
          }
      }

      .chart__cta {
        background-color: $sc-c-3;
        padding: 1.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 1.75rem 0 -1rem $sc-c-1;

        &::after {
          background-image: linear-gradient(to bottom, rgba($sc-c-2, 0), $sc-c-2);
          content: "";
          display: block;
          position: absolute;
          height: 48px;
          top: -48px;
          left: 0;
          right: 0;
          pointer-events: none;

          @include tablet {
            height: 96px;
            top: -96px;
          }
        }
      }
    }
  }
}
