.is-feature-items {
  .title:first-child:not(:last-child) {
    margin-bottom: 3rem;

    @include desktop {
      margin-bottom: 6rem;
    }
  }

  .feature {
    padding: 1rem;
    background-color: rgba($sc-c-3, 0.3);
    border-radius: $radius;
    .icon {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    @include tablet {
      min-height: 320px;
    }
  }
}

.feature-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: calc(var(--column-index, 0) * 100ms);

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }

  // Add delay based on column position
  @for $i from 0 through 12 {
    &:nth-child(#{$i}) {
      --column-index: #{$i};
    }
  }
}
