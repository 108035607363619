.is-content-page__header {
  &.is-hero {
    @include desktop {
      min-height: calc(
        96vh - 286px
      ); // 84 navbar, 202 reference-gallery one row
    }

    .hero-body {
      padding-top: 0;
      padding-bottom: 0;

      .columns {
        @include mobile {
          flex-direction: column-reverse;
        }
      }

      .field.has-addons {
        max-width: 26rem;
      }

      .visual {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        min-width: 50vw;
        img {
          max-height: 400px;
        }
      }
    }
  }
}

.is-content-page__content {
  color: $white;

  // min-height: 50vh;
  // padding-top: 3rem;
  // padding-bottom: 3rem;

  // .section {
  //   padding: 1.5rem;
  // }

  // .container {
  //   max-width: 720px;
  // }

  .content {
    strong {
      color: inherit;
    }

    a {
      color: $primary;

      &:hover {
        color: darken($primary, 10%);
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
}

.is-minimalist {
  > .container {
    box-shadow: 0 0 0px 0 rgba(10, 10, 10, 0.3),
      0 8px 16px -4px rgba(10, 10, 10, 0.25);

    .container {
      max-width: 800px;
    }
  }

  .is-content-page__content {
    p,
    ul,
    li {
      font-family: $family-serif;
    }
  }
}
