#navbarBlogPostHeader {
  position: fixed;
  top: 0;
  transform: translateY(-200px);
  transition: transform 250ms;

  @include desktop {
    &.is-active {
      transform: translateY(0);
    }
  }

  .navbar-menu {
    flex-shrink: 1;
    overflow: hidden;

    .navbar-start {
      overflow: hidden;
      justify-items: center;
      padding: 0 1.5rem;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: none;
      }
    }
  }
}

@include desktop {
  #navbarHeader.is-blogpost {
    position: relative;
    top: 0;
  }
}

.is-blog-post-page {
  background-color: $white;
}

.is-blog-post-page__header {
  position: relative;
  background-color: rgba($sc-c-9, 0.1);

  .blog-post__cover {
    margin: -3rem -1.5rem 3rem -1.5rem;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  }

  .title {
    margin: 1.5rem 0;
    color: $sc-c-0;
  }

  .blog-post__meta {
    .media {
      align-items: center;
    }

    .author__name {
      font-family: $family-serif;
    }
  }

  @include desktop {
    .container {
      display: flex;
      align-items: center;
    }
    .blog-post__cover {
      margin: 0 3rem 0 -3rem;
      flex-basis: calc(53% + 3rem);
      flex-shrink: 0;
    }
    .blog-post__meta {
      margin-right: -3rem;
    }
  }
}

.is-blog-post-page__content {
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-bottom: 3rem;

  --icon-color: #{rgba($sc-c-9, 0.75)};

  a.icon:hover {
    --icon-color: #{rgba($sc-c-9, 1)};
  }

  .icon {
    margin: 4px;
  }

  .section {
    padding: 1.5rem;

    &.is-separator {
      position: relative;
      text-align: center;
      padding: inherit;

      &::before {
        background-image: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0) 0,
          $sc-c-2 5%,
          $sc-c-2 90%,
          rgba(255, 255, 255, 0) 100%
        );
        content: "";
        display: block;
        height: 2px;
        opacity: 0.08;
        position: absolute;
        margin: auto;
        max-width: 720px;
        top: 50%;
        left: 0;
        right: 0;
      }
    }

    .container {
      max-width: 720px;
    }
  }

  .content {
    color: rgba($sc-c-1, 0.8);
    line-height: 1.8;
    font-size: 1.175em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $sc-c-0;
    }

    p,
    ul,
    li {
      font-family: $family-serif;
    }

    [data-oembed-provider="YouTube"] {
      display: block;
      margin: 1.5rem auto;

      div {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .is-highlight {
      padding: 0.25rem 0.35rem;
      margin: 0 0.15rem;
      background-color: rgba($sc-c-9, 0.25);
      color: rgba($sc-c-1, 1);
    }

    .block-img {
      &.is-left-align {
        max-width: 40%;
        margin: 0 1.5rem 0 0;
        float: left;
      }
      &.is-right-align {
        max-width: 40%;
        margin: 0 0 0 1.5rem;
        float: right;
      }
    }

    // [data-oembed-provider="YouTube"] iframe {
    //   display: block;
    //   margin: 1.5rem auto;

    //   @include mobile {
    //     min-height: 11.5rem;
    //     height: auto;
    //     width: 100%;
    //   }
    // }

    a {
      color: $primary;

      &:hover {
        color: darken($primary, 10%);
      }
    }
  }

  .socials {
    border: 1px solid rgba($grey-light, 0.5);
    border-radius: 4px;
    padding: 1rem;

    h2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: 0.75rem;
    }
    .icon {
      vertical-align: middle;
    }
  }

  .is-fly {
    position: sticky;
    bottom: 60vh;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;

    .container {
      max-width: unset;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 125ms;

      &.is-visible {
        opacity: 1;
      }

      a {
        pointer-events: auto;
      }
    }
  }

  .related-articles {
    background-color: rgba($sc-c-9, 0.1);
    margin-top: 3rem;
    margin-bottom: -3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .blog-post {
      figure {
        margin-bottom: 1.5rem;
        transition: transform 250ms;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
          opacity: 0;
          transition: opacity 250ms;
        }

        img {
          object-fit: cover;
        }
      }

      .blog-post__header:hover {
        figure {
          transform: scale(1.0125);
          &:before {
            opacity: 1;
          }
        }
      }

      .blog-post__date {
        opacity: 0.3;
      }
    }
  }

  .toc {
    ul {
      padding-left: 1.5rem;
    }
    a {
      color: $sc-c-0;
      &:hover {
        color: rgba($sc-c-0, 0.6);
      }
    }

    li {
      padding: 0.25rem 0;
      &.is-size-5 {
        list-style-type: disc;
      }
    }
  }

  .author {
    position: relative;
    margin-top: 1.5rem;

    &::before {
      background-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0,
        $sc-c-2 5%,
        $sc-c-2 90%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      display: block;
      height: 2px;
      opacity: 0.08;
      position: absolute;
      margin: auto;
      max-width: 960px;
      top: 0;
      left: 0;
      right: 0;
    }
    &::after {
      background-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0,
        $sc-c-2 5%,
        $sc-c-2 90%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      display: block;
      height: 2px;
      opacity: 0.08;
      position: absolute;
      margin: auto;
      max-width: 960px;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .author__name {
      font-family: $family-serif;
    }
  }

  .reading-progress-bar {
    height: 0.25rem;
    background-color: $primary;
    position: sticky;
    z-index: 1;
    top: 3.25rem;
    transition: opacity 250ms 250ms;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }

    @include desktop {
      top: 5.25rem;
    }
  }
}
