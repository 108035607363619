.is-blog-category-page__header {

  .hero-body {
    
    @include desktop {
      padding-top: 3rem !important;
    }
  }
}

.is-blog-category-page__content {
  background-color: $white;
  min-height: 50vh;

  .blog-post {

    figure {
      margin-bottom: 1.5rem;

      img {
        object-fit: cover;
      }
    }

    .blog-post__excerpt {
      margin: 0.75rem 0;
      opacity: .6;
      font-family: $family-serif;
    }

    .blog-post__category {
      opacity: .3;
    }

    .blog-post__date {
      opacity: .3;
    }

    &.is-expanded {

      @include tablet {
        display: flex;
        align-items: center;
  
        .blog-post__header {
          flex-grow: 1;
        }
        .blog-post__body {
          flex-basis: 55%;
          padding: 3rem;
        }
      }
  
    }

    figure {
      transition: transform 250ms; 

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: opacity 250ms;
      }
    }

    .blog-post__header:hover {
      figure {
        transform: scale(1.0125);
        &:before {
          opacity: 1;
        }
      }
    }

  }

}
