.is-pricing-page__header {
  .subtitle {
    @include mobile {
      font-size: 1.175rem !important;
    }
  }

  .plan-toggle {
    margin: 2rem auto 0;
  }
}

.is-pricing-page__content {
  padding-top: 0rem;

  .plans-grid {
    display: grid;
    gap: 1rem;
    justify-content: center;

    @include mobile {
      grid-template-columns: 1fr;
    }

    @include tablet {
      grid-template-columns: repeat(auto-fit, minmax(280px, 320px));
    }

    .plan {
      border: 1px solid $sc-c-2;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      border-radius: $radius;
      font-weight: $weight-normal;
      align-items: flex-start;

      figure {
        margin-bottom: 1.5em;
      }
      .price {
        margin-bottom: 1.5rem;
      }
      .buttons {
        margin-top: auto;
      }

      &.has-highlight-shadow {
        box-shadow: rgb(21 29 49) 15px 30px 250px 0px;
        border: 1px solid $sc-c-3;
      }
    }
  }

  .plan-details {
    padding: 1.5rem;

    @include tablet {
      padding-top: 0;
    }

    p {
      margin-bottom: 1em;
    }

    h5 {
      margin: 1em 0;
    }

    .plan-details__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  }

  .price {
    strong {
      color: $white;
      font-size: 2rem;
    }
  }

  .is-included::before {
    content: "";
    background-image: url("/img/status-on.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 0.75rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
    vertical-align: middle;
  }
  .is-excluded {
    //opacity: .5;
    color: $sc-c-5;
  }
  .is-excluded::before {
    content: "";
    background-image: url("/img/status-off.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 0.75rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
    vertical-align: middle;
  }
}

.is-pricing-page__content + .is-feature-table {
  .md-container table:first-child {
    position: sticky;
    top: 3.15rem;
    z-index: 1;

    @include desktop {
      top: 5.25rem;
    }

    tr:first-child {
      background-color: transparent;
      transition: all 0.4s ease;

      td:not(:first-child) {
        border-bottom: 1px solid rgba($sc-c-3, 1);
        vertical-align: bottom;
      }
    }

    &.is-sticky tr:first-child {
      background-color: #080d1a;
    }
  }

  table {
    table-layout: fixed;
    th:first-child,
    td:first-child {
      width: 36%;
    }
    td .button {
      height: auto;
      white-space: normal;
    }
  }
}

.plan-toggle {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;

  .toggle-container {
    display: flex;
    background-color: $sc-c-2;
    border-radius: 9px;
    width: fit-content;
    box-shadow: rgb(21, 29, 49) 15px 30px 250px 0px;
    border: 1px solid #25365c;
  }

  .toggle-option {
    cursor: pointer;
    transition: all 0.2s ease;
    color: $white;
    font-size: $size-7;

    &:first-child {
      padding: 0.4rem 1.4rem 0.4rem 1.6rem;
      border-radius: $radius 0 0 $radius;
      border-right: 0px;
    }

    &:last-child {
      border-radius: 0 $radius $radius 0;
      padding: 0.4rem 1.6rem 0.4rem 1.4rem;
    }

    &.active {
      background-color: $sc-c-text;
      color: $sc-c-1;
    }
  }
}
