
.is-datasources-page__header {
  h1.title {
    margin-bottom: 3rem;
    line-height: 2.875rem;

    @include mobile {
      font-size: 8.72vw;
      line-height: 1.3em;
      margin-top: 4rem;
      font-weight: 300 !important;
    }

    @media (min-width: 600px){
      font-size: 6vw;
      line-height: 1.2em;
    }

    @include tablet {
      margin-bottom: 6rem;
    }
    @include desktop {
      margin-top: 3rem;
      margin-bottom: 9rem;
      letter-spacing: 0.0675rem;
      line-height: 3.5rem;
    }
  }

  .content {
    margin-bottom: 3rem;
    @include mobile {
      font-size: 1.175rem !important;
    }
  }
}

.is-datasources-page__content {

}

.is-datasources-page__content + .is-datasource {
  padding-top: 0;
}

.is-datasource + .is-datasource {
  &::before {
    background-image: linear-gradient(-90deg,rgba(255,255,255,0) 0,#fff 5%,#fff 90%,rgba(255,255,255,0) 100%);
    content: "";
    display: block;
    height: 2px;
    opacity: .08;
    position: absolute;
    margin: auto;
    max-width: 960px;
    top: 0;
    left: 0;
    right: 0;
  }
}

.is-airplay + .is-request {
  padding-top: 0;
}