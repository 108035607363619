.is-airplay {
  .airplay-item {
    position: relative;
    &:nth-child(odd) {
      background-color: $sc-c-2;
    }
    &:hover {
      background-color: $sc-c-3;
    }

    .airplay-item-title {
      position: relative;
      padding: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tooltip {
        display: inline-block;
        white-space: nowrap;
        margin-left: 1.5rem;
      }
    }

    .airplay-item-label {
      display: flex;
      align-items: center;

      .chevron-icon {
        flex-shrink: 0;
        transition: transform 0.2s ease;
        margin-right: 0.75rem;
        opacity: 0.6;
        transform: rotate(-90deg);
      }

      &:hover .chevron-icon {
        opacity: 1;
      }

      .chevron-icon.is-expanded {
        transform: rotate(0deg);
      }
    }

    .airplay-item-list {
      display: none;
      padding: 0 1.5rem 0.75rem 1.5rem;

      ul {
        column-count: 2;

        @include tablet {
          column-count: 4;
        }
      }
    }

    &.is-expanded {
      background-color: $sc-c-3;
      .airplay-item-list {
        display: block;
      }
    }
  }
}
