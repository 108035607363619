.is-text-block {
    span.is-left-align {
        display: block;
        text-align: left;
    }
    span.is-center-align {
        display: block;
        text-align: center;
    }
    span.is-right-align {
        display: block;
        text-align: right;
    }

    ul {
     list-style: none;
        margin-top: 0;
        li {
        padding-left: 1.25rem;
        position: relative;
        }
        li::before {
        content: "■";
        position: absolute;
        left: 0;
        font-size: 1em;
        color: $primary;
        }
    }

    [data-oembed-provider="Instagram"] {

        div {

            iframe {
                margin: auto !important;
                display: block;
            }
        }
    }
    [data-oembed-provider="Soundsgood"] {
        display: block;
        margin: 1.5rem auto;
  
        div {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
  
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
}