.is-feature-table {
  .md-container {
    @include tablet {
      margin-top: 3rem;
    }

    margin-bottom: 1.5rem;

    table {
      width: 100%;

      thead tr th {
        color: $sc-white;
        text-align: left;
      }
      td,
      th {
        padding: 1rem;
      }
      th:not(:first-child),
      td:not(:first-child) {
        text-align: center;
      }

      tr:not(:first-child) {
        td:first-child {
          p:first-child {
            margin-left: 1rem;
          }
        }
      }

      tr {
        border-bottom: 1px solid rgba($sc-c-3, 1);
      }

      a:not(.button) {
        color: $link !important;
      }
    }
    table:first-of-type {
      td:first-of-type {
        font-size: $size-6;
        vertical-align: bottom;

        @include desktop {
          font-size: $size-3;
        }
        font-weight: 600;

        p {
          word-break: break-word;
          white-space: pre-line;
          line-height: 1.125;
          margin: 0;
        }

        strong {
          display: block;
          word-spacing: 100vw;
        }
      }
    }

    table:not(:first-of-type) {
      tr:first-child {
        font-size: 1.25rem;
        margin-top: 1rem;
        height: 6rem;
        border-bottom: none;
        td,
        th {
          vertical-align: middle;
        }
      }
      tr:not(:first-child) {
        td:first-of-type {
          color: $sc-c-10;
        }
      }
    }

    table:last-of-type tr:last-child {
      border-bottom: none;
    }
  }
}
