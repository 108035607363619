.is-pricing-explained-page__content {
  padding-bottom: 6rem;

  .pricing-description {
    figure {
      margin-bottom: 1.5rem;
    }
  }

  .symbol {
    padding: 6rem 0;
    display: block;
  }

  .pricing-table {
    @include tablet {
      margin-top: 3rem;
    }

    margin-bottom: 1.5rem;

    table {
      width: 100%;

      thead tr th {
        color: $sc-white;
        text-align: left;
      }
      td,
      th {
        padding: 1rem;
      }
      th:not(:first-child) {
        text-align: center;
      }
      td:not(:first-child) {
        text-align: center;
      }
      thead tr:nth-of-type(1) {
        background-color: $sc-c-3;
      }
      thead tr:nth-of-type(2) {
        background-color: $sc-c-4;
      }

      thead tr:not(:first-child),
      tbody {
        td:nth-of-type(3),
        td:nth-of-type(4) {
          background-color: rgba($sc-c-4, 0.2);
        }
        th:nth-of-type(3),
        th:nth-of-type(4) {
          background-color: rgba($sc-c-3, 0.6);
        }
      }
      a {
        color: $link !important;
      }
    }
  }
}
