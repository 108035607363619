@charset "utf-8";

// // Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Inter:400,500,700|PT+Serif:400,400i,700,700i&display=swap");

@import url("https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/3.6.95/css/materialdesignicons.min.css");

// Set your brand colors
$sc-white: #ffffff;
$sc-white-light: rbga(255, 255, 255, 0.8);

$sc-c-brand: #e45d5e;

$sc-c-0: #04070f;
$sc-c-1: #0c1528;
$sc-c-2: #172542;
$sc-c-3: #25365c;
$sc-c-4: #344875;
$sc-c-5: #475d8f;
$sc-c-6: #5d73a8;
$sc-c-7: #748bc2;
$sc-c-8: #8fa5db;
$sc-c-9: #abc1f5;
$sc-c-10: #e8eeff;

$sc-c-ok: #3da80e;
$sc-c-error: #d81a1a;
$sc-c-warning: #ffb65f;
$sc-c-info: #359be0;

$sc-c-text: rgba(255, 255, 255, 0.9);

// Update Bulma's global variables
$family-sans-serif: "Inter", sans-serif;
$family-serif: "PT Serif", serif;

$primary: $sc-c-brand;

$link: $sc-white;
$link-hover: darken($link, 10%);

$info: $sc-c-info;
$success: $sc-c-ok;
$warning: $sc-c-warning;
$danger: $sc-c-error;

$body-background-color: transparent;
$footer-background-color: transparent;

$dark: $sc-c-0;
$light: $sc-white;

$easy-read: $sc-c-text;
$title-color: $sc-white;
$title-weight: 500;
$subtitle-color: rgba($sc-white, 0.9);

$custom-colors: (
  "semi-light": $sc-c-9,
  "c-0": $sc-c-0,
  "c-1": $sc-c-1,
  "c-2": $sc-c-2,
  "c-3": $sc-c-3,
  "c-4": $sc-c-4,
);

$input-color: $sc-white;
$input-background-color: $sc-c-4;
$input-placeholder-color: rgba($easy-read, 0.5);
$input-border-color: transparent;
$input-hover-border-color: transparent;
$input-focus-border-color: transparent;
$input-focus-box-shadow-color: transparent;
$input-shadow: none;
$input-radius: 8px;
$radius: 8px;

$size-1: 3rem !default; //48px
$size-2: 2.625rem !default; //42px
$size-3: 2.25rem !default; //36px
$size-4: 1.875rem !default; //30px
$size-5: 1.375rem !default; //22px
$size-6: 1rem !default; //16px
$size-7: 0.875rem !default; //14px
$size-8: 0.75rem !default; //12px

// $size-small: $size-7 !default;
// $size-normal: $size-5 !default;
// $size-medium: $size-4 !default;
// $size-large: $size-3 !default;

// $subtitle-size: $size-4 !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 !default;

@import "~bulma/bulma.sass";

.has-text-weight-medium {
  font-weight: $weight-medium !important;
}

// Colors
.section {
  padding: 2rem 1rem;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: transparent;
      color: $color-invert;
      a:not(.button):not(.dropdown-item):not(.tag),
      strong {
        color: $white;
        text-transform: none;
      }
      .title {
        color: $color-invert;
      }
      .subtitle {
        color: rgba($color-invert, 0.9);
        a:not(.button),
        strong {
          color: $color-invert;
        }
      }
      .nocreditcard {
        color: $sc-white;
      }
    }
  }
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.buttons .button.is-image {
  padding: 0;
  background: transparent;
  border-color: transparent;
}

@import "./utils/module";
@import "./base/module";
@import "./fragments/module";
@import "./pages/module";

[data-tooltip] {
  position: relative;
  cursor: help;
  &::after {
    content: attr(data-tooltip);
    position: absolute;
    max-width: 40vw;
    transition: 0.2s ease;
    background-color: $white;
    color: $dark;
    padding: 1rem;
    font-size: $size-7;
    font-weight: 600;
    width: max-content;
    line-height: 1.4;
    border-radius: 0.25rem;
    box-shadow: 0 5px 10px rgba($white, 0.3);
    z-index: 10;

    top: -1rem;
    left: 100%;
    transform: translateX(-0.5rem);
    opacity: 0;
    visibility: hidden;
  }
  &:hover::after {
    opacity: 1;
    visibility: visible;
    transform: translateX(0.5rem);
  }
  &.has-tooltip-top {
    &::after {
      top: auto;
      left: auto;
      transform: translateY(-50%) translateX(-50%);
    }
    &:hover::after {
      transform: translateY(-110%) translateX(-50%);
    }
  }
}

.loader-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loader-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader-dual-ring 1.2s linear infinite;
}
@keyframes loader-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input::placeholder {
  color: $input-placeholder-color;
}

.easy-read {
  color: $sc-c-text;
}

.columns.is-multiline {
  margin: -0.5rem;

  .column {
    padding: 0.5rem;
  }
}

.has-mobile-bottom-spacing {
  @include mobile {
    padding-bottom: 4rem !important;
  }
}

.has-radius {
  border-radius: $radius;
}
