html,
body,
#root {
  min-height: 100vh;
  // scroll-behavior: smooth;
  scroll-padding-top: 3.25rem;
  background-attachment: fixed;

  @include desktop {
    scroll-padding-top: 5.25rem;
  }
}

main {
  min-height: calc(50vh);
  background: linear-gradient(to bottom, $sc-c-0, $sc-c-1, $sc-c-0);

  .section .container,
  .hero .container {
    max-width: 1024px;
  }
  .hero .container-pricing {
    max-width: 1280px;
  }
}

.icon svg {
  width: 100%;
  height: 100%;
}
